html {
  font-size: initial;
}

.password-input + .eye-icon {
  display: none;
}

.password-input:focus + .eye-icon {
  background-image: url("eye-closed.e19f3774.svg");
  background-size: contain;
  display: block !important;
}

.eye-icon:hover {
  background-size: contain;
  background-image: url("eye-open.69b83a59.svg") !important;
}

.navbar-toggler .icon {
  width: 24px;
  height: 24px;
  background-image: url("menu-active.3901c6f7.svg");
  display: block;
}

.navbar-toggler[aria-expanded="false"] .icon {
  background-image: url("menu.4042ef7c.svg");
}

.logos img {
  width: 180px;
  height: auto;
}

.tooltip-download {
  --mdb-tooltip-bg: #d9d9d9;
  margin-top: .5rem !important;
  margin-left: 3rem !important;
}

.tooltip-download .tooltip-inner {
  max-width: 19.125rem;
  border-radius: .5rem;
  padding: .75rem;
  color: #00365b !important;
  background-color: #d9d9d9 !important;
}

.tooltip-download .tooltip-arrow {
  --mdb-tooltip-bg: #d9d9d9 !important;
  display: block !important;
  left: -7rem !important;
}

.tooltip-qrcode {
  z-index: 9;
  transform: initial !important;
  left: 64px !important;
}

.download:hover {
  background-color: #00365b !important;
}

.qrcode {
  background-image: url("qrcode.d0e53067.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.qrcode:hover {
  background-image: url("qrcode-active.4c9181f7.png");
}

.bg-image-vertical {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  overflow: hidden;
}

input:focus {
  border: 1px solid #0068b0 !important;
  outline: none !important;
}

ul .list-group-item {
  border: 0;
}

#sidebarMenu a {
  color: #3f4855;
  font-size: .875rem;
}

.sidebar-order-icon {
  width: 18px;
  height: 18px;
  background-image: url("orders.d0c18cac.svg");
  background-size: contain;
}

.sidebar-active .sidebar-order-icon {
  background-image: url("orders-active.3a79d19a.svg");
}

.sidebar-devices-icon {
  width: 18px;
  height: 18px;
  background-image: url("devices.7dde0851.svg");
  background-size: contain;
}

.sidebar-active .sidebar-devices-icon {
  background-image: url("devices-active.64ac5d9c.svg");
}

.sidebar-invitation-icon {
  width: 18px;
  height: 18px;
  background-image: url("invitation.1bf62acf.svg");
  background-size: contain;
}

.sidebar-active .sidebar-invitation-icon {
  background-image: url("invitation-active.9c3e0778.svg");
}

.sidebar-enterprise-icon {
  width: 18px;
  height: 18px;
  background-image: url("folder.32ff3160.svg");
  background-size: contain;
}

.sidebar-active .sidebar-enterprise-icon {
  background-image: url("folder-active.0b7adcf9.svg");
}

.sidebar-dashboard-icon {
  width: 18px;
  height: 18px;
  background-image: url("home.5a708259.svg");
  background-size: contain;
}

.sidebar-active .sidebar-dashboard-icon {
  background-image: url("home-active.0514e32f.svg");
}

.sidebar-active {
  color: #0068b0 !important;
}

table tbody tr:hover {
  background-color: #f5f7fa;
}

table tbody th a:hover {
  text-decoration: underline;
}

table .status {
  border-radius: 2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

table .status-active {
  color: #064e00;
  background-color: #e4f7dd;
}

table .status-inactive {
  color: #4e0900;
  background-color: #f7e9dd;
}

table .status-current {
  color: #00426f;
  background-color: #deeaff;
}

.enterprise-info input {
  width: 36.5rem;
  height: 2.25rem;
  background-color: #f8fbff;
  border: 1px solid #c9defc;
  font-size: .875rem;
  color: #1d2939 !important;
}

.enterprise-info label.required:before {
  content: "";
  width: .5rem;
  height: .5rem;
  background-image: url("required.04aacea9.svg");
  margin-top: -.3rem;
  margin-right: .25rem;
}

.enterprise-tabs .nav-tabs .nav-link.active {
  color: #0068b0;
  border-color: #0068b0;
}

.enterprise-tabs .nav-tabs .nav-link {
  color: #3f4855;
  border-bottom: 4px solid #0000;
}

.faq-tabs .nav-tabs .nav-link.active, .faq-tabs .nav-tabs .nav-link:hover {
  color: #0068b0;
  background-color: #fff;
  border-color: #0068b0;
}

.faq-tabs .nav-tabs .nav-link {
  color: #3f4855;
  border-bottom: 4px solid #0000;
}

.news-tabs .nav-tabs .nav-link.active, .news-tabs .nav-tabs .nav-link:hover {
  color: #0068b0;
  background-color: #fff;
  border-color: #0068b0;
  padding-bottom: .25rem !important;
  font-weight: 700 !important;
}

.news-tabs .nav-tabs .nav-link {
  color: #000;
  border-bottom: 4px solid #0000;
  font-weight: normal !important;
}

.news-content img {
  width: 100%;
  align-self: center;
}

.fee-plan-tabs .nav-item .nav-link.active {
  color: #0068b0 !important;
  background-color: #fff !important;
}

.fee-plan-tabs .nav-item .nav-link:hover {
  color: #fff !important;
  background-color: #0068b0 !important;
}

.fee-plan-tabs .nav-item .nav-link.active:hover {
  color: #0068b0 !important;
  background-color: #fff !important;
}

.home-banner .carousel .carousel-inner .carousel-item {
  height: 39.31rem;
  width: 56.69rem;
}

.home-banner .carousel .carousel-inner .banner-1 {
  background-image: url("banner-1.60268d17.svg");
  background-size: contain;
}

.home-banner .carousel .carousel-inner .banner-2 {
  background-image: url("banner-2.68207860.svg");
  background-size: contain;
}

.home-banner .carousel .carousel-inner .banner-3 {
  background-image: url("banner-3.affd85ef.svg");
  background-size: contain;
}

main .carousel-indicators > div button {
  height: 4px;
  opacity: 1;
  border: 0;
  margin: 0;
  background-color: #c5dcec !important;
}

.home-main .carousel-indicators > div button {
  width: 16.67%;
}

.case-main .carousel-indicators > div button {
  width: 50%;
}

main .carousel-indicators .active {
  background-color: #0068b0 !important;
}

main .carousel-indicators .carousel-control-prev, main .carousel-indicators .carousel-control-next {
  width: 3.5rem;
  height: 3.5rem;
  opacity: 1;
  position: initial;
  border: none;
  margin: 0;
}

main .carousel-indicators .carousel-control-prev {
  background-image: url("prev.ad2f6529.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

main .carousel-indicators .carousel-control-prev:hover {
  background-image: url("next.2f170a8d.svg");
  background-size: contain;
  transform: rotate(180deg);
}

main .carousel-indicators .carousel-control-next {
  background-image: url("prev.ad2f6529.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}

main .carousel-indicators .carousel-control-next:hover {
  background-image: url("next.2f170a8d.svg");
  background-size: contain;
  transform: rotate(0);
}

footer .footer-container {
  background-color: #0068b0;
  justify-content: space-evenly;
  padding: 2.5rem 4.5rem 4rem;
  display: flex;
}

footer .footer-container .footer-top {
  gap: 5.5rem;
  display: flex;
}

.footer-top .footer-nav {
  border-right: 1px solid #fff9;
  flex-direction: column;
  gap: 1rem;
  padding-right: 6rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.footer-top a {
  text-decoration: none;
}

.footer-nav a {
  color: #fff;
  flex-grow: 0;
  text-decoration: none;
}

.footer-container .footer-logo {
  color: #fff;
  gap: 10px;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.footer-logo img {
  width: 134px;
  height: 42px;
}

.footer-container .footer-medias {
  gap: 30px;
  display: flex;
}

.footer-media img {
  width: 24px;
  height: 24px;
}

.footer-right {
  flex-direction: column;
  justify-content: space-between;
  margin-left: 22.9375rem;
  display: flex;
}

.footer-container .footer-bottom {
  justify-content: space-between;
  margin-left: 5.25rem;
  display: flex;
}

.footer-right .footer-bottom-left {
  color: #fff9;
  font-size: .875rem;
}

.footer-bottom .footer-bottom-right {
  color: #fff;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.footer-bottom-right a {
  margin-top: 28px;
}

.footer-bottom-right address {
  font-style: normal;
}

.footer-bottom-right a {
  color: #fff;
  text-decoration: none;
}

.auth-tip {
  visibility: hidden;
}

@media (width >= 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}

.anim-x-scroller {
  float: left;
  animation: 22s linear infinite alternate x-scroller;
}

@keyframes x-scroller {
  0% {
    margin-left: 0;
    transform: translateX(0);
  }

  5% {
    margin-left: 0;
    transform: translateX(0);
  }

  95% {
    margin-left: 100%;
    transform: translateX(-100%);
  }

  100% {
    margin-left: 100%;
    transform: translateX(-100%);
  }
}

.folder-sample:hover, .folder-home:hover, .sidebar-item:hover, .folder-item:hover, .folder-sample.active, .folder-home.active, .sidebar-item.active, .folder-item.active {
  background-color: #0068b00d !important;
}

.folder-sample.active .fs-14, .folder-home.active .fs-14, .sidebar-item.active .fs-14, .folder-item.active {
  color: #1c2124 !important;
}

.location-info:before {
  content: "";
  width: .75rem;
  height: .75rem;
  background-image: url("location.6db05d2a.svg");
  background-size: contain;
  display: inline-block;
}

.view-grid {
  background-image: url("view-grid.df0575fa.svg");
  background-size: contain;
}

.view-grid-active {
  background-size: contain;
  background-image: url("view-grid-active.7c9ec869.svg") !important;
}

.view-list {
  background-image: url("view-list.0dee5594.svg");
  background-size: contain;
}

.view-list-active {
  background-size: contain;
  background-image: url("view-list-active.3fee7055.svg") !important;
}

.show .dropdown-icon {
  transition: all .5s;
  transform: rotate(180deg);
}

.dropdown-icon {
  transition: all .5s;
  transform: rotate(0);
}

.dropdown-menu.dropdown-menu-more {
  border-radius: .25rem;
  box-shadow: 0 10px 32px #00365b1a;
}

.dropdown-menu-more .dropdown-item {
  height: 2.25rem;
}

.dropdown-menu-more .dropdown-item:hover, .dropdown-menu-more .dropdown-item:focus {
  background-color: #0068b014;
}

.dropdown-menu-more .dropdown-item:hover span, .dropdown-menu-more .dropdown-item:focus span {
  font-weight: 700;
}

.entry:hover {
  background-color: #0068b014;
}

.entry-active div {
  color: #00365b !important;
}

.i18n-menu .entry-active div {
  font-weight: bold;
}

.entry-active .check-icon {
  visibility: visible;
}

.check-icon {
  visibility: hidden;
}

.sort-menu:hover {
  border: 1px solid #0068b0;
  background: #0068b014 !important;
}

.models-list-container.models-list-container {
  width: 100%;
  height: auto;
  min-height: calc(100% - 11rem);
  box-sizing: border-box;
  overflow-x: auto;
}

.models-list-container .models-list-row-title {
  width: calc(21.45vw - 5rem);
  min-width: calc(284px - 5rem);
}

.models-list-container .models-list-row-user {
  width: calc(12vw - 1rem);
  min-width: calc(160px - 1rem);
}

.models-list-container table > :not(caption) > * > * {
  padding: 1.25rem 0 1.25rem 1rem;
}

.models-list-container table {
  min-width: 1034px;
  border-color: #00365b0d;
}

.models-list-container table th {
  white-space: nowrap;
  padding-right: 1rem;
}

.models-list-container table tbody .table-row-active, .models-list-container table tbody tr:hover {
  background-color: #0068b00d;
  position: relative;
}

.models-list-container table tbody tr:hover .models-list-check {
  content: "";
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-image: url("list-uncheck.f76c9e77.svg");
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.models-list-container table tbody tr:hover .dropdown {
  opacity: 1 !important;
}

.models-list-container table tbody tr .dropdown-menu {
  display: none;
}

.models-list-container table tbody tr:hover .dropdown-menu.show {
  display: block;
}

.models-list-container table tbody .table-row-active:hover .models-list-check, .models-list-container table tbody .table-row-active .models-list-check {
  content: "";
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-image: url("check-fill.25a54f24.svg");
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.model-check-icon {
  background: url("uncheck.bc600676.svg") 0 0 / contain no-repeat;
}

#models-container .table-row-active .model-check-icon, .active .model-check-icon, .list-group-item:hover .model-check-icon, .active .model-check-icon {
  background: url("check-fill.25a54f24.svg") 0 0 / contain no-repeat;
  display: block;
}

#models-container .model-card {
  width: calc(16.66% - 1.67rem);
  min-width: 200px;
}

#models-container .model-check-icon {
  display: none;
}

#models-container .model-card:hover .model-check-icon {
  display: block;
}

.list-group-item.active, #models-container .table-row-active, .modal-folders .list-group-item:hover {
  background: #0068b014 !important;
}

#models-container .model-card:hover {
  box-shadow: 0 10px 48px #00365b33;
}

#models-container .model-card:hover .view-more {
  display: block;
}

#models-container .table-row-active:after {
  content: "";
  pointer-events: none;
  z-index: 0;
  border: 1px solid #0068b0;
  border-radius: 8px;
  position: absolute;
  inset: -1px;
}

#models-container .table-row-active .view-more {
  display: none;
}

@media (width <= 1000px) {
  .models-list-container.models-list-container {
    min-height: calc(100% - 13rem);
  }

  .models-list-top {
    flex-wrap: wrap;
  }

  .models-list-top-left {
    width: 100%;
  }

  .models-list-top-right {
    margin-top: 8px;
  }

  .models-list-top-right .models-list-top-right-first {
    margin-left: 0 !important;
  }
}

@media (width <= 744px) {
  .models-list-container.models-list-container {
    min-height: auto;
  }

  .search-name.search-name {
    height: 20px;
  }
}

.modal-folders {
  border: 1px solid #00365b1a;
}

.modal-folders .list-group-item {
  border-bottom: 1px solid #00365b1a;
}

.modal-folders .list-group-item:last-child {
  border-bottom: none;
}

.search-name {
  height: 1.25rem;
  box-sizing: content-box;
}

.search-name::placeholder {
  color: #00365b99;
}

.search-name::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background: url("cancel.f9f77cc2.svg") 0 0 / contain no-repeat;
  margin-right: 1.25rem;
}

.view-more {
  background: url("more-fill.805d1b24.svg") 0 0 / contain no-repeat;
  display: none;
  -webkit-appearance: none !important;
}

.view-list-more {
  background: url("list-more.b7cf539f.svg") 0 0 / contain no-repeat;
  -webkit-appearance: none !important;
}

.view-more.show, .view-more:hover {
  background: url("more-focus.7f21ed59.svg") 0 0 / contain no-repeat;
  display: block;
}

.view-list-more.show, .view-list-more:hover {
  background: url("list-more-active.62ace2ea.svg") 0 0 / contain no-repeat;
}

.dropup .view-list-more.dropdown-toggle:after {
  display: none;
}

.breadcrumb-item:before {
  content: "/";
  display: inline-block;
  height: 1.25rem !important;
  padding-right: .75rem !important;
}

.breadcrumb-item {
  display: flex;
  height: 1.5rem !important;
  color: #809aad !important;
  padding-left: .75rem !important;
}

.sub-nav-breadcrumb-home, .breadcrumb-item a {
  color: #809aad !important;
}

.sub-nav-breadcrumb-home.active, .breadcrumb-item.active {
  font-weight: bold;
  color: #394953 !important;
}

.dark-mode {
  background-color: #272e32 !important;
}

.dark-mode .dropdown-toggle {
  color: #c2c7cb !important;
}

.dark-mode .customer-name, .dark-mode .home-register, .dark-mode .home-login {
  color: #fff !important;
}

.dark-mode .home-register {
  background-color: #0000 !important;
  border-color: #fff !important;
}

.landing-card:hover {
  box-shadow: 0 10px 40px #00365b1a;
  border-color: #0068b0 !important;
}

.with-folder-icon {
  cursor: pointer;
  align-items: center;
  display: flex;
  color: #00365b99 !important;
  font-size: .875rem !important;
}

.with-folder-icon:before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: url("folder-icon.ef8f810d.svg") 0 0 / contain no-repeat;
  flex-shrink: 0;
  margin-right: .75rem;
  display: inline-block;
}

.sidebar-tree-view .accordion-button:after {
  width: .875rem;
  height: .875rem;
  background-size: contain;
}

.sidebar-tree-view .accordion-button:not(.collapsed):after, .sidebar-tree-view .accordion-button:after {
  background-image: url("sidebar-arrow.49c08d22.svg");
}

.select-user {
  max-height: 8rem;
}

.scroller {
  overflow-y: auto;
}

.scroller::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: none;
}

.scroller::-webkit-scrollbar-thumb {
  background: #00365b33;
}

.btn-select-all .active {
  background-image: url("check-fill.25a54f24.svg") !important;
}

.navbar-item {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
}

.navbar-item:hover {
  color: #00365b;
  background: #0068b014;
}

.navbar-item.active {
  color: #00365b;
  font-weight: bold;
}

.navbar-item.active:hover {
  background: inherit;
  color: inherit;
}

.models-section, .models-tip {
  background-color: #f8fbff !important;
}

.models-section {
  position: relative;
}

.pagination .page-link {
  color: #00365b99;
  font-size: .75rem;
}

.pagination {
  align-items: center;
  display: flex;
}

.pagination .page-prev {
  margin-right: .75rem;
}

.pagination .page-next {
  margin-left: .75rem;
}

.pagination .active .page-link {
  background-color: #0068b0;
  color: #fff !important;
}

.tooltip-name.tooltip-name .tooltip-inner {
  max-width: 10.75rem;
  text-align: left;
  background-color: #000;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.125rem;
  box-shadow: 0 10px 32px #00365b1a;
}

@media only screen and (width <= 1780px) {
  #models-container .model-card {
    width: calc(25% - 1.5rem);
  }
}

/*# sourceMappingURL=index.2878c509.css.map */
